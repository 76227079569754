import React from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import {
  SearchOff as SearchOffIcon,
  FilterAltOff as FilterAltOffIcon,
  Refresh as RefreshIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

const NoResultsFound = ({ 
  hasActiveFilters, 
  onResetFilters, 
  onBackToSearch,
  searchQuery 
}) => {
  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 4, 
        textAlign: 'center',
        backgroundColor: 'background.default',
        borderRadius: 2,
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}
    >
      {hasActiveFilters ? (
        <FilterAltOffIcon sx={{ fontSize: 64, color: 'text.secondary', mb: 2 }} />
      ) : (
        <SearchOffIcon sx={{ fontSize: 64, color: 'text.secondary', mb: 2 }} />
      )}

      <Typography variant="h5" gutterBottom>
        {hasActiveFilters ? 'No matches found with current filters' : 'No results found'}
      </Typography>

      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        {hasActiveFilters 
          ? "Try adjusting your filters to see more results"
          : `We couldn't find any businesses matching "${searchQuery}"`}
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
        {hasActiveFilters && (
          <Button
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={onResetFilters}
          >
            Reset Filters
          </Button>
        )}
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={onBackToSearch}
        >
          Back to Search
        </Button>
      </Box>
    </Paper>
  );
};

export default NoResultsFound;