import React, { useMemo, useState, useEffect } from 'react';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { Paper, Typography, Box, Chip, Rating, CircularProgress } from '@mui/material';
import { LocationOn as LocationIcon, Warning as WarningIcon } from '@mui/icons-material';

const BusinessMap = ({ 
  businesses, 
  userLocation,
  containerStyle = { width: '100%', height: '400px' }
}) => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  
  useEffect(() => {
    // Check if Google Maps is available
    if (window.google && window.google.maps) {
      setMapLoaded(true);
    } else {
      console.error('Google Maps not found. Make sure it is loaded in index.html');
    }
  }, []);

  // Filter businesses with valid coordinates
  const validBusinesses = useMemo(() => {
    return businesses.filter(business => {
      const coordinates = business.geocodeResults?.location?.coordinates;
      return coordinates && 
             Array.isArray(coordinates) && 
             coordinates.length === 2 &&
             coordinates[0] != null && 
             coordinates[1] != null;
    });
  }, [businesses]);
  console.log('validBusinesses', validBusinesses)
  console.log('userLocation', userLocation)

  // Calculate map center and bounds
  const mapConfig = useMemo(() => {
    if (!window.google || validBusinesses.length === 0) {
      return {
        center: userLocation 
          ? { lat: userLocation.lat, lng: userLocation.lng }
          : { lat: 40.7128, lng: -74.0060 }, // New York as fallback
        zoom: 12
      };
    }

    // Calculate bounds to fit all markers
    const bounds = new window.google.maps.LatLngBounds();
    validBusinesses.forEach(business => {
      const [lng, lat ] = business.geocodeResults.location.coordinates;
      bounds.extend({ lat, lng });
    });

    // Add user location to bounds if available
    if (userLocation) {
      bounds.extend(userLocation);
    }

    return {
      center: {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng()
      },
      zoom: 12
    };
  }, [validBusinesses, userLocation]);

  if (!mapLoaded) {
    return (
      <Paper 
        sx={{ 
          p: 3, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}
      >
        <CircularProgress size={24} sx={{ mr: 2 }} />
        <Typography>Loading map...</Typography>
      </Paper>
    );
  }

  // Show message if no businesses have valid coordinates
  if (validBusinesses.length === 0) {
    return (
      <Paper 
        sx={{ 
          p: 3, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          backgroundColor: 'warning.light'
        }}
      >
        <WarningIcon sx={{ mr: 1 }} />
        <Typography>
          {businesses.length === 0 
            ? 'No businesses to display on map'
            : 'None of the current businesses have valid location data'}
        </Typography>
      </Paper>
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapConfig.center}
      zoom={mapConfig.zoom}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: true
      }}
    >
      {/* User location marker */}
      {userLocation && (
        <MarkerF
          position={userLocation}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: '#4285F4',
            fillOpacity: 1,
            strokeColor: '#ffffff',
            strokeWeight: 2,
          }}
        />
      )}

      {/* Business markers */}
      {validBusinesses.map(business => {
        const [lng, lat] = business.geocodeResults.location.coordinates;
        console.log('business2.geocodeResults.location.coordinates', business.geocodeResults.location.coordinates)
        return (
          <MarkerF
            key={business.businessId}
            position={{ lat, lng }}
            onClick={() => setSelectedBusiness(business)}
          />
        );
      })}

      {/* Info Window */}
      {selectedBusiness && (
        <InfoWindowF
          position={{
            lat: selectedBusiness.geocodeResults.location.coordinates[1],
            lng: selectedBusiness.geocodeResults.location.coordinates[0]
          }}
          onCloseClick={() => setSelectedBusiness(null)}
        >
          <Box sx={{ maxWidth: 200 }}>
            <Typography variant="subtitle1" gutterBottom>
              {selectedBusiness.businessName}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {selectedBusiness.skillName}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Rating
                value={selectedBusiness.avgSkillRating_all}
                precision={0.1}
                readOnly
                size="small"
              />
              <Typography variant="body2" sx={{ ml: 1 }}>
                ({selectedBusiness.count_all})
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
              {selectedBusiness.featureMap?.slice(0, 2).map((feature) => (
                <Chip
                  key={feature}
                  label={feature}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
        </InfoWindowF>
      )}
    </GoogleMap>
  );
};

export default BusinessMap;