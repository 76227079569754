import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useSpFindBusinessNameMutation } from '../../redux/thunks/sp/spfindbusinessname';
import { useGetUserTrendingQuery } from '../../redux/thunks/users/gettrending';
import { useGetTopBusinessesQuery } from '../../redux/thunks/unauth/gettopbusinesses';

import TrustSignals from './TrustSignals';



import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  InputBase,
  Divider,
  IconButton,
  Chip,
  Card,
  CardContent,
  Skeleton,
  Rating,
  AvatarGroup, 
  Avatar,
  
} from '@mui/material';
import {
  Search as SearchIcon,
  LocationOn as LocationIcon,
  ArrowForward as ArrowForwardIcon,
  People as PeopleIcon,
  Star as StarIcon,
  Reviews as ReviewsIcon,
  Verified as  VerifiedIcon,
  Security as SecurityIcon,
  RestaurantOutlined as RestaurantIcon,
  LocalCafeOutlined as CafeIcon,
  StorefrontOutlined as RetailIcon,
  FitnessCenterOutlined as FitnessIcon,
  ContentCutOutlined as BeautyIcon,
  TheaterComedyOutlined as EntertainmentIcon,
  PeopleAltOutlined as NetworkIcon,
  StarsOutlined as RatedIcon,
  NewReleasesOutlined as NewIcon,
  SecurityOutlined as InsuranceIcon
} from '@mui/icons-material';

// Mock Data
const POPULAR_SEARCHES = [
  'Italian Restaurants',
  'Coffee Shops',
  'Bookstores',
  'Fitness Centers'
];

const CATEGORIES = [
    { 
      icon: RestaurantIcon, 
      name: 'Restaurants', 
      count: 245,
      color: 'primary.main' // You can customize colors for each category
    },
    { 
      icon: CafeIcon, 
      name: 'Cafes', 
      count: 122,
      color: 'secondary.main'
    },
    { 
      icon: RetailIcon, 
      name: 'Retail Stores', 
      count: 167,
      color: 'success.main'
    },
    { 
      icon: FitnessIcon, 
      name: 'Fitness & Health', 
      count: 89,
      color: 'warning.main'
    },
    { 
      icon: BeautyIcon, 
      name: 'Beauty & Spa', 
      count: 156,
      color: 'error.main'
    },
    { 
      icon: EntertainmentIcon, 
      name: 'Arts & Entertainment', 
      count: 78,
      color: 'info.main'
    },
    {
      icon: InsuranceIcon,
      name: 'Insurance',
      count: 93,
      color: 'purple.main' // Using a different color for distinction
    }
  ];
  

  const QUICK_LINKS = [
    {
      icon: NetworkIcon,
      title: 'Trusted by Your Network',
      description: 'See where your connections shop',
      color: 'primary.main'
    },
    {
      icon: RatedIcon,
      title: 'Highly Rated Places',
      description: 'Top-rated businesses near you',
      color: 'warning.main'
    },
    {
      icon: NewIcon,
      title: 'New on Revfer',
      description: 'Recently added businesses',
      color: 'success.main'
    }
   ];


  
  
  const LoadingStateComponent = () => (
    <>
      {/* Network Trending Loading State */}
      <Box sx={{ mt: 3 }}>
        <Skeleton variant="text" width="200px" height={32} />
        <Paper sx={{ p: 2, mt: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ flex: 1 }}>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={20} />
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, gap: 1 }}>
                <Skeleton variant="rectangular" width={100} height={20} />
                <Skeleton variant="text" width={120} height={20} />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} variant="circular" width={32} height={32} />
              ))}
            </Box>
          </Box>
          <Skeleton variant="text" width="40%" height={20} sx={{ mt: 1 }} />
        </Paper>
      </Box>
  
      {/* Trust Signals Loading State */}
      <Box sx={{ mt: 4 }}>
        <Skeleton variant="text" width="150px" height={32} />
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {[1, 2, 3, 4].map((index) => (
            <Grid item xs={12} key={index}>
              <Paper sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
                      <Skeleton variant="circular" width={24} height={24} />
                      <Skeleton variant="text" width="40%" height={20} />
                    </Box>
                    <Skeleton variant="text" width="70%" height={24} />
                    <Skeleton variant="text" width="50%" height={20} />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, gap: 1 }}>
                      <Skeleton variant="rectangular" width={100} height={20} />
                      <Skeleton variant="text" width={120} height={20} />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
// Component for the Trending and Trust Signals section
const TrendingAndTrustSignals = ({ trendingBusinesses, topBusinesses, isLoading }) => {
  if (isLoading) {
    return <LoadingStateComponent />;
  }

  return (
    <>
      

      {/* Dynamic Trust Signals Section */}
      <TrustSignals topBusinesses={topBusinesses} />
    </>
  );
};
// Component for the Search Bar
const SearchBar = ({ onSearch, isLoading }) => {
  const [query, setQuery] = useState('');
  const [location, setLocation] = useState('');

  const handleSearch = () => {
    onSearch({ query, location });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  if (isLoading) {
    return (
      <Paper sx={{ p: 3 }}>
        <Skeleton variant="text" width="60%" height={40} />
        <Skeleton variant="text" width="40%" height={20} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={60} sx={{ borderRadius: 3 }} />
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Find Trusted Businesses
      </Typography>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        Discover businesses recommended by people you trust
      </Typography>

      <Paper
        elevation={0}
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          mt: 2,
          bgcolor: 'grey.50',
          borderRadius: 3
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, pl: 2 }}>
          <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
          <InputBase
            placeholder="Search businesses or categories"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyPress}
            sx={{ flex: 1 }}
          />
        </Box>

        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

        <Box sx={{ display: 'flex', alignItems: 'center', width: '30%', px: 2 }}>
          <LocationIcon sx={{ color: 'text.secondary', mr: 1 }} />
          <InputBase
            placeholder="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            onKeyDown={handleKeyPress}
            sx={{ flex: 1 }}
          />
        </Box>

        <IconButton 
          onClick={handleSearch}
          sx={{ 
            bgcolor: 'primary.main',
            color: 'white',
            borderRadius: 1,
            mr: 0.5,
            '&:hover': {
              bgcolor: 'primary.dark',
            }
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Paper>

      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Popular searches:
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {POPULAR_SEARCHES.map((term) => (
            <Chip
              key={term}
              label={term}
              onClick={() => {
                setQuery(term);
                handleSearch();
              }}
              sx={{ bgcolor: 'primary.50' }}
            />
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

// Component for Category Browser
const CategoryBrowser = ({ onCategorySelect, isLoading }) => {
  if (isLoading) {
    return (
      <Paper sx={{ p: 2 }}>
        <Skeleton variant="text" width="60%" height={32} />
        {[1, 2, 3, 4].map((i) => (
          <Skeleton key={i} variant="rectangular" height={50} sx={{ mt: 1, borderRadius: 1 }} />
        ))}
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' sx={{ p: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Browse by Category
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {CATEGORIES.map((category) => {
        const Icon = category.icon;
        return (
            <Paper
            key={category.name}
            elevation={0}
            onClick={() => onCategorySelect(category.name)}
            sx={{
                p: 2,
                bgcolor: 'grey.50',
                cursor: 'pointer',
                '&:hover': {
                bgcolor: 'grey.100',
                }
            }}
            >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon sx={{ 
                    mr: 2, 
                    color: category.color,
                    fontSize: 24 
                }} />
                <Typography>{category.name}</Typography>
                </Box>
                <Typography variant="caption" color="text.secondary">
                ({category.count})
                </Typography>
            </Box>
            </Paper>
        );
        })}
      </Box>
    </Paper>
  );
};

// Component for Quick Links
const QuickLinks = ({ onQuickLinkSelect, isLoading }) => {
  if (isLoading) {
    return (
      <Paper sx={{ p: 2 }}>
        <Skeleton variant="text" width="60%" height={32} />
        {[1, 2, 3].map((i) => (
          <Skeleton key={i} variant="rectangular" height={60} sx={{ mt: 1, borderRadius: 1 }} />
        ))}
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' sx={{ p: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Quick Links
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {QUICK_LINKS.map((link) => {
        const Icon = link.icon;
        return (
        <Paper
            key={link.title}
            elevation={0}
            onClick={() => onQuickLinkSelect(link.title)}
            sx={{
            p: 2,
            bgcolor: 'grey.50',
            cursor: 'pointer',
            '&:hover': {
                bgcolor: 'grey.100',
            }
            }}
        >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
            <Icon sx={{ 
                color: link.color,
                fontSize: 24 
            }} />
            <Box>
                <Typography variant="subtitle2">{link.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                {link.description}
                </Typography>
            </Box>
            </Box>
        </Paper>
        );
        })}
      </Box>
    </Paper>
  );
};

// Component for Discovery Methods
const DiscoveryMethods = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ mt: 3 }}>
        <Skeleton variant="text" width="40%" height={32} />
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {[1, 2].map((i) => (
            <Grid item xs={12} sm={6} key={i}>
              <Skeleton variant="rectangular" height={160} sx={{ borderRadius: 1 }} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Ways to Find Trusted Businesses
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              height: '100%',
              bgcolor: 'grey.50',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <PeopleIcon sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Through Your Network</Typography>
            </Box>
            <Typography color="text.secondary">
              Find businesses your connections trust and recommend
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              height: '100%',
              bgcolor: 'grey.50',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <StarIcon sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Smart Ratings</Typography>
            </Box>
            <Typography color="text.secondary">
              Discover highly-rated businesses using our AI-powered insights
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

// Main Find Business Page Component
const FindBusinessPage = () => {
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);  
  const [isLoading, setIsLoading] = useState(true);

  const { data: trendingData,  isError: istrendingDataError,  isLoading: isTrendingLoading,  error: trendingError,} = useGetUserTrendingQuery();
  console.log('trendingData', trendingData)

  const args = {   
    authType: 'none',
    token: token
  }

  const { data: topBusinessesData, error: topBusinessesError, isLoading: isTopBusinessesLoading } = useGetTopBusinessesQuery(args);
  console.log('topBusinessesData', topBusinessesData)

  


  


  // Simulate loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

 

  const handleSearch = async ({ query, location }) => {
    console.log('Searching:', { query, location });
    const userFlag = false
    navigate('/searchresults', { state: { query, location, userFlag } });

  
  };

  const handleCategorySelect = (query) => {
    console.log('Category selected:', query);
    const userFlag = true
    navigate('/searchresults', { state: { query, userFlag } });
  };

  const handleQuickLinkSelect = (link) => {
    console.log('Quick link selected:', link);
    navigate('/search', { state: { quickLink: link } });
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Grid container spacing={3}>
        {/* Left Column - Categories */}
        <Grid item xs={12} md={3}>
          <CategoryBrowser
            onCategorySelect={handleCategorySelect}
            isLoading={isLoading}
          />
        </Grid>

        {/* Middle Column - Search and Discovery */}
        <Grid item xs={12} md={6}>
          <SearchBar
            onSearch={handleSearch}
            isLoading={isLoading}
          />
          <TrendingAndTrustSignals 
            trendingBusinesses={trendingData}
            topBusinesses={topBusinessesData}
            isLoading={isTrendingLoading || isTopBusinessesLoading} />
        </Grid>

        {/* Right Column - Quick Links */}
        <Grid item xs={12} md={3}>
          <QuickLinks
            onQuickLinkSelect={handleQuickLinkSelect}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default FindBusinessPage;