import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Rating,
  Chip,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Slider,
  Pagination,
  Skeleton,
  LinearProgress,
  Avatar,
  Card,
  CardContent,
  IconButton,
  FormControlLabel,
  Checkbox,
  Collapse,
  Tooltip, 
  Button,
  
  
  
} from '@mui/material';
import {
  LocationOn as LocationIcon,
  Star as StarIcon,
  AccessTime as TimeIcon,
  TrendingUp as TrendingIcon,
  AttachMoney as MoneyIcon,
  Info as InfoIcon,
  People as PeopleIcon, 
  Language as LanguageIcon,
  Warning as WarningIcon,
  AddBusinessOutlined as AddBusinessIcon,
  SearchOutlined as SearchIcon,
  Map as MapIcon

} from '@mui/icons-material';

import BusinessMap from './BusinessMap';


import { useSelector } from 'react-redux'; // For auth state
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSpFindBusinessNameMutation } from '../../redux/thunks/sp/spfindbusinessname';

import { usePrefetch as useGetUserCacheDataPrefetch } from '../../redux/thunks/users/usercachedata';
import { useLazyGetUserCacheDataQuery } from '../../redux/thunks/users/usercachedata';
import { useLazyGetBusinessesByQuickQryQuery } from '../../redux/thunks/unauth/getbusinessesbyquickqry';

import NoResultsFound from './NoResultsFound';  // Create this component in a separate file





const calculateDistance = (userLat, userLng, businessLat, businessLng) => {
  if (!businessLat || !businessLng) return null;
  
  // Haversine formula implementation
  const R = 3959; // Earth's radius in miles
  const dLat = (businessLat - userLat) * Math.PI / 180;
  const dLng = (businessLng - userLng) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(userLat * Math.PI / 180) * Math.cos(businessLat * Math.PI / 180) * 
    Math.sin(dLng/2) * Math.sin(dLng/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
};




// Mock Data


const CATEGORY_TYPES = {
  PHYSICAL: 'physical',
  ONLINE: 'online'
};


//We need a logic to render categories on the fly based on the search results, Show top 5 categories


const SORT_OPTIONS = [
  { value: 'relevance', label: 'Most Relevant' },
  { value: 'rating', label: 'Highest Rated' },
  { value: 'reviews', label: 'Most Reviewed' },
  { value: 'distance', label: 'Nearest' }
];


// Distance filter options
const DISTANCE_OPTIONS = [
  { label: 'Any Distance', value: 'all' },
  { label: 'Within 5 miles', value: 5 },
  { label: 'Within 10 miles', value: 10 },
  { label: 'Within 25 miles', value: 25 },
  { label: 'Within 50 miles', value: 50 }
];

// Mock Insights Data
const INSIGHTS = [
  {
    title: "Businesses Added Recently",
    content: "We’ve added 50 new businesses in the past week! Explore now to find your next favorite place.",
    icon: <TrendingIcon color="primary" />,
  },
  {
    title: "Popular Categories",
    content: "Top categories include Restaurants, Cafes, and Fitness Centers. Discover businesses in your favorite category.",
    icon: <StarIcon color="primary" />,
  },
  {
    title: "Claim Your Business",
    content: "80% of businesses are unclaimed. Claim yours today to start receiving reviews and insights!",
    icon: <AddBusinessIcon color="primary" />,
  },
  {
    title: "Nearby Businesses",
    content: "Discover businesses within 5 miles of your location. Start exploring local favorites now!",
    icon: <LocationIcon color="primary" />,
  },
  {
    title: "Top Features",
    content: "Most businesses offer Free WiFi, Parking, and Pet-Friendly spaces. See what’s available near you.",
    icon: <InfoIcon color="primary" />,
  },
];

// Skeleton Components
const ResultCardSkeleton = () => (
  <Paper sx={{ p: 2, mb: 2 }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Skeleton variant="circular" width={64} height={64} />
      <Box sx={{ flex: 1 }}>
        <Skeleton variant="text" width="60%" height={28} />
        <Skeleton variant="text" width="40%" height={24} />
        <Skeleton variant="text" width="30%" height={24} />
      </Box>
    </Box>
    <Skeleton variant="text" width="100%" sx={{ mt: 2 }} />
    <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
      <Skeleton variant="rounded" width={80} height={32} />
      <Skeleton variant="rounded" width={80} height={32} />
      <Skeleton variant="rounded" width={80} height={32} />
    </Box>
  </Paper>
);

const InsightsSkeleton = () => (
  <Paper sx={{ p: 2 }}>
    <Skeleton variant="text" width="60%" height={32} />
    <Skeleton variant="rectangular" height={100} sx={{ mt: 2 }} />
    <Skeleton variant="text" width="40%" height={24} sx={{ mt: 2 }} />
    <Skeleton variant="rectangular" height={80} sx={{ mt: 1 }} />
    <Skeleton variant="text" width="50%" height={24} sx={{ mt: 2 }} />
    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
      <Skeleton variant="rounded" width={100} height={32} />
      <Skeleton variant="rounded" width={100} height={32} />
    </Box>
  </Paper>
);

const getRandomInsights = (count = 2) => {
  const shuffled = [...INSIGHTS].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};


const computeCategoryOptions = (searchResults) => {
  // First, count occurrences of each skillName
  const categoryCount = searchResults.reduce((acc, result) => {
    const skillName = result.skillName;
    acc[skillName] = (acc[skillName] || 0) + 1;
    return acc;
  }, {});

  // Convert to array and sort by count
  const sortedCategories = Object.entries(categoryCount)
    .sort((a, b) => b[1] - a[1]); // Sort by count in descending order

  // Get top 5 categories
  const topCategories = sortedCategories.slice(0, 5).map(([skillName]) => ({
    label: skillName,
    value: skillName.toLowerCase(),
    type: CATEGORY_TYPES.PHYSICAL, // You might want to determine this dynamically
    count: categoryCount[skillName]
  }));

  // Calculate "Others" count - sum of all categories not in top 5
  const othersCount = sortedCategories.slice(5).reduce((sum, [_, count]) => sum + count, 0);

  // Construct final category options
  const categoryOptions = [
    { label: 'All Categories', value: 'all', type: null, count: searchResults.length },
    ...topCategories
  ];

  // Add "Others" category if there are remaining categories
  if (othersCount > 0) {
    categoryOptions.push({
      label: 'Others',
      value: 'others',
      type: null,
      count: othersCount
    });
  }

  return categoryOptions;
};

// Main Component
export default function SearchResultsPage() {
  const locationData = useLocation();
  const navigate = useNavigate();
  const randomInsights = getRandomInsights();


  const { query, userFlag, location: searchLocation } = locationData.state || {};

  //const searchResultsTmp = locationData.state?.response || '';
  //const cacheId = searchResultsTmp?.data?.cacheId;
  //const searchResultsData = searchResultsTmp?.data?.results || [];
  //const searchParams = locationData.state?.searchParams || [];
  const [loading, setLoading] = useState(true);
  //const [searchResults, setSearchResults] = useState([]);
  const [category, setCategory] = useState('All Categories');
  const [ratingFilter, setRatingFilter] = useState(0);
  const [sortBy, setSortBy] = useState('relevance');
  const [page, setPage] = useState(1);
  const [resultsPerPage] = useState(5);
  const [location, setLocation] = useState('all');
  const [latestResults, setLatestResults] = useState([]);

  const [filteredResults, setFilteredResults] = useState([]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [maxDistance, setMaxDistance] = useState('all');
  const [userCoordinates, setUserCoordinates] = useState({
    lat: null,
    lng: null,
    loading: true,
    error: null
  });

  const [cacheId, setCacheId] = useState('');
  const [fetchResultsTrigger, setFetchResultsTrigger] = useState(false);
  const [showInsidersOnly, setShowInsidersOnly] = useState(false);

  const [showMap, setShowMap] = useState(false);




  /*States to show and hide certain components*/
  const [notHide, setNotHide] = useState(false);

  const token = useSelector((state) => state.auth?.token || null);  


  console.log('query', query)
  console.log('userFlag', userFlag)

  
  //console.log('searchLocation', searchLocation)
  
  console.log('cacheId', cacheId)
  console.log('latestResults', latestResults)
  console.log('loading', loading)
  console.log('filteredResults1', filteredResults)




  const [thisToken, setThisToken] = useState(token);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { authStatus } = useAuthenticator(context => [context.authStatus]);



  // Determine if selected category is physical location based
  const selectedCategoryData = categoryOptions.find(cat => cat.value === category);
  const isPhysicalLocation = selectedCategoryData?.type === CATEGORY_TYPES.PHYSICAL;

  const [ triggerSearch, { data: MysearchResultsData, isLoading: isBusinessLoading }, ] = useSpFindBusinessNameMutation();
  const prefetchGetUserCacheData = useGetUserCacheDataPrefetch('getUserCacheData');
  const [getCacheData, { data: resultsCacheData, isLoading: cacheLoading }] = useLazyGetUserCacheDataQuery();

  const [getBusinessQuickQry, { data: quickBusinessesQryData, isLoading: isQuickBusinessQryLoading }] = useLazyGetBusinessesByQuickQryQuery();

  console.log('MysearchResultsData', MysearchResultsData)
  console.log('quickBusinessesQryData', quickBusinessesQryData)
  console.log('userCoordinates', userCoordinates)



  

  useEffect(() => {
    if (query && !userFlag) {
      const auth = {
        token: thisToken, // Replace with actual token logic
        authType: 'token',
      };
      const payload = {
        auth,
        text: query,
      };

      triggerSearch({ payload });
    }

    if (query && userFlag) {
      const auth = {
        token: thisToken, // Replace with actual token logic
        authType: 'token',
      };
      const payload = {
        token: thisToken,
        query,
      };

      getBusinessQuickQry( payload );
    }
  }, [query, triggerSearch, userFlag, getBusinessQuickQry]);

  useEffect(() => {
    if (MysearchResultsData) {
      setLatestResults(MysearchResultsData.results)
      setCacheId(MysearchResultsData.cacheId)
      setFilteredResults(MysearchResultsData.results)
    }
  }, [MysearchResultsData]);

  useEffect(() => {
    if (quickBusinessesQryData) {
      setLatestResults(quickBusinessesQryData.results)
      setCacheId(quickBusinessesQryData.cacheId)
      setFilteredResults(quickBusinessesQryData.results)
    }
  }, [quickBusinessesQryData]);

 

  useEffect(() => {
    setLoading(isBusinessLoading || cacheLoading || isQuickBusinessQryLoading)
    
  }, [isBusinessLoading, cacheLoading, isQuickBusinessQryLoading]);


   // Get user's location when component mounts
   useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            loading: false,
            error: null
          });
        },
        (error) => {
          setUserCoordinates(prev => ({
            ...prev,
            loading: false,
            error: 'Unable to get your location'
          }));
        }
      );
    } else {
      setUserCoordinates(prev => ({
        ...prev,
        loading: false,
        error: 'Geolocation not supported'
      }));
    }
  }, []);

  useEffect(() => {
    if (latestResults.length > 0) {
      const options = computeCategoryOptions(latestResults);
      setCategoryOptions(options);
      
      // Reset category selection if current category is no longer valid
      if (!options.find(opt => opt.value === category)) {
        setCategory('All Categories');
      }
    }
  }, [latestResults]);


  

  useEffect(() => {
    if (authStatus === 'authenticated') {
      setThisToken(token);
      setIsAuthenticated(true)
    }
  }, [authStatus, token]);

  useEffect(() => {
      if (resultsCacheData) {
        console.log('resultsCacheData', resultsCacheData)
        const uniqueObjectIdsArray = resultsCacheData.uniqueObjectIdsArray || [];
        const stringObjectIds = uniqueObjectIdsArray.map((objectId) => objectId.businessId.toString());
        console.log('uniqueObjectIdsArray', uniqueObjectIdsArray)
        console.log('stringObjectIds', stringObjectIds)
        console.log('latestResults', latestResults)

  
        const filteredLatestResults = latestResults.filter((business) =>
          stringObjectIds.includes(business._id)
        );
        console.log('filteredLatestResults', filteredLatestResults)

        showInsidersOnly && setLatestResults(filteredLatestResults);
        !showInsidersOnly && setLatestResults(MysearchResultsData?.results || quickBusinessesQryData?.results || []);
  
  
      }

      
  }, [resultsCacheData, fetchResultsTrigger, MysearchResultsData, quickBusinessesQryData, showInsidersOnly]);

  
  useEffect(() => {

    console.log('Filter here, ', latestResults)
    

   
      // Filter and sort results
      const filteredResultsData = latestResults
      .filter(result => {
        // Basic category filter
        if (category !== 'All Categories') {
          console.log('aaaaaa')
          if (category === 'others') {
            console.log('bbbbbbb')
            
            // For "others" category, exclude items that are in top 5
            const topCategories = categoryOptions
              .filter(opt => opt.value !== 'all' && opt.value !== 'others')
              .map(opt => opt.value);
            if (topCategories.includes(result.skillName.toLowerCase())) {
              return false;
            }
          } else {
            console.log('cccccc')
            // For specific category selection
            if (result.skillName.toLowerCase() !== category) {
              console.log('dddddd')
              return false;
            }
          }
        }
        if (maxDistance !== 'all' && userCoordinates.lat && userCoordinates.lng) {
          console.log('eeeeeee')
          if (!result.geocodeResults?.location?.coordinates) {
            console.log('fffffff')
            // Keep businesses without coordinates but they'll show with a warning
            return true;
          }
          const distance = calculateDistance(
            userCoordinates.lat,
            userCoordinates.lng,
            result.geocodeResults.location.coordinates[0],
            result.geocodeResults.location.coordinates[1]
          );
          console.log('distance', distance)
          if (distance > maxDistance) {
            console.log('gggggg')
            return false;
          }
        }
      
        
        // Rating filter
        if (ratingFilter > 0 && result.avgSkillRating_all < ratingFilter) {
          console.log('hhhhhh')
          return false;
        
        }
        
        // Location filter - only apply if physical location
        if (isPhysicalLocation && 
            location !== 'all' && 
            result.physicalLocationDetails?.neighborhood.toLowerCase() !== location) {
              console.log('jjjjjj')
          return false;
        }

        console.log('check here')
        
        
        
        return true;
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'rating':
            return b.avgSkillRating_all - a.avgSkillRating_all;
          case 'reviews':
            return b.reviews - a.reviews;
          case 'distance':
            return a.distance - b.distance;
          default:
            return 0;
        }
  });    
  console.log('filteredResultsData', filteredResultsData)
    
    setFilteredResults(filteredResultsData);
   
  }, [latestResults, isAuthenticated, showInsidersOnly, ratingFilter, category]);

  


  //console.log('category', category)
  //console.log('categoryOptions', categoryOptions)
  const handleFindBusiness = () => navigate('/find');
  const handleAddBusiness = () => navigate('/company/new');


  const handleDiscoverFromNetwork = (isChecked) => {
    console.log('isChecked', isChecked)
    getCacheData(cacheId);
    setFetchResultsTrigger(!fetchResultsTrigger);
  };

  

  console.log('filteredResults', filteredResults)


  const paginatedResults = filteredResults.slice(
    (page - 1) * resultsPerPage,
    page * resultsPerPage
  );

  console.log('paginatedResults', paginatedResults)

  const isValidCoordinates = (coordinates) =>
    Array.isArray(coordinates) &&
    coordinates.length === 2 &&
    coordinates[0] != null &&
    coordinates[1] != null;

  const hasActiveFilters = () => {
    return category !== 'All Categories' ||
            ratingFilter > 0 ||
            maxDistance !== 'all' ||
            showInsidersOnly;
  };
  
  const handleResetFilters = () => {
    setCategory('All Categories');
    setRatingFilter(0);
    setMaxDistance('all');
    setShowInsidersOnly(false);
    setPage(1);
  };

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <InsightsSkeleton />
          </Grid>
          <Grid item xs={12} md={6}>
            {Array.from({ length: 5 }).map((_, index) => (
              <ResultCardSkeleton key={index} />
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <InsightsSkeleton />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Updated Header */}
      <Paper variant="outlined" sx={{ p: 2, mb: 3, border: 'none' }}>
        <Typography variant="h5" align="center">
          Explore Businesses in Your Area
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          Discover new businesses and find trusted connections.
        </Typography>
      </Paper>

      <Grid container spacing={3}>
        {/* Left Column - Filters */}
        <Grid item xs={12} md={3}>
          <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
            <Typography variant="h6" gutterBottom>
              Filters
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Category
            </Typography>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categoryOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography>{option.label}</Typography>
                    <Typography color="text.secondary">({option.count})</Typography>
                  </Box>
                </MenuItem>
              ))}
              </Select>
            </FormControl>

          {/* Location filter  */}
            <Typography variant="subtitle2" gutterBottom>
              Distance
            </Typography>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <Select
                value={maxDistance}
                onChange={(e) => setMaxDistance(e.target.value)}
                disabled={userCoordinates.loading}
              >
                {DISTANCE_OPTIONS.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {userCoordinates.error && (
                <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                  {userCoordinates.error}
                </Typography>
              )}
            </FormControl>

            

            <Typography variant="subtitle2" gutterBottom>
              Minimum Rating
            </Typography>
            <Rating
              value={ratingFilter}
              onChange={(e, newValue) => setRatingFilter(newValue)}
              precision={0.5}
              sx={{ mb: 3 }}
            />

              {/* Insiders filter - only show if authenticated */}
            <Collapse in={isAuthenticated}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showInsidersOnly}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setShowInsidersOnly(isChecked);
                      handleDiscoverFromNetwork(isChecked); 
                    }}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle2">
                        Show Insider Connections Only
                      </Typography>
                      <Tooltip title="Shows results from businesses connected through your network">
                        <InfoIcon fontSize="small" color="action" />
                      </Tooltip>
                    </Box>
                  
                }
                sx={{ mb: 2 }}
              />
            </Collapse>
          </Paper>
        </Grid>

        {/* Middle Column - Results */}
        <Grid item xs={12} md={6}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mb: 3,
              border: 'none',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {/* Left Side: Find Business and Add Business */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                onClick={handleFindBusiness}
              >
                Find Businesses
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddBusinessIcon />}
                onClick={handleAddBusiness}
              >
                Add a Business
              </Button>
            </Box>

            {/* Right Side: Sort Options */}
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Sort by' }}
              >
                {SORT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              startIcon={<MapIcon />}
              onClick={() => setShowMap(!showMap)}
              sx={{ mb: 1 }}
            >
              {showMap ? 'Hide Map' : 'Show Map'}
            </Button>
            <Collapse in={showMap}>
              <BusinessMap 
                businesses={filteredResults}
                userLocation={userCoordinates.lat && userCoordinates.lng ? {
                  lat: userCoordinates.lat,
                  lng: userCoordinates.lng
                } : null}
              />
            </Collapse>
          </Box>
          {filteredResults.length > 0 ? (
            <>
             {paginatedResults.map((result) => (
            <Paper 
              variant="outlined" 
              key={result.id} 
              sx={{ p: 2, mb: 2, border: 'none', cursor: 'pointer' }}
              onClick={() => navigate(`/business/${result.businessId}/${result.businessName.replace(/[\s,\.]+/g, '-').toLowerCase()}`)}
              
              >
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Avatar
                  variant="rounded"
                  src={result.photoUrl}
                  sx={{ width: 64, height: 64 }}
                >
                  {result.businessName[0]}
                </Avatar>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">{result.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                  {result.skillName.charAt(0).toUpperCase() + result.skillName.slice(1)}
                  </Typography>
                  {result.businessType === CATEGORY_TYPES.PHYSICAL && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <LocationIcon fontSize="small" color="action" />
                      <Typography variant="body2">
                        {result.physicalLocationDetails.neighborhood[0]} ({result.distance} miles)
                      </Typography>
                    </Box>
                  )}
                    {result.businessType === CATEGORY_TYPES.ONLINE && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LanguageIcon fontSize="small" color="action" />
                        <Typography variant="body2">
                           Service Areas: {result.onlineDetails.shippingRegions[0]}
                        </Typography>
                      </Box>
                    )}

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocationIcon fontSize="small" color="action" />
                    <Typography variant="body2">
                      {result.location} ({result.distance} placeholder)
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Rating value={result.avgSkillRating_all} precision={0.1} readOnly size="small" />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      ({result.count_all})
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    Smart Rating: {Math.round(result.SkillValue_all*10)/10} 
                  </Typography>
                </Box>
              </Box>

              <Typography variant="body2" sx={{ mt: 2 }}>
                {result.description}
              </Typography>

              <Box sx={{ display: 'flex', gap: 1, mt: 2, flexWrap: 'wrap' }}>
                {result.featureMap?.map((feature) => (
                  <Chip
                    key={feature}
                    label={feature}
                    size="small"
                  />
                ))}
                {result.verified && (
                  <Chip
                    label="Verified"
                    color="success"
                    size="small"
                    variant="outlined"
                  />
                )}
                {result.openNow && (
                  <Chip
                    label="Open Now"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                )}
                  {result.isInsiderConnection && (
                    <Chip
                      icon={<PeopleIcon />}
                      label="Insider Connection"
                      size="small"
                      color="primary"
                      sx={{ mt: 1 }}
                    />
                  )}
              </Box>
              <Box sx={{ mt: 1 }}>
              {isValidCoordinates(result.geocodeResults?.location?.coordinates) ? (
                <Typography variant="body2" color="text.secondary">
                  <LocationIcon fontSize="small" sx={{ mr: 1 }} />
                  {calculateDistance(
                    userCoordinates.lat,
                    userCoordinates.lng,
                    result.geocodeResults.location.coordinates[1],
                    result.geocodeResults.location.coordinates[0]
                  ).toFixed(1)} miles away
                </Typography>
              ) : (
                maxDistance !== 'all' && (
                  <Typography
                    variant="body2"
                    color="warning.main"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <WarningIcon fontSize="small" sx={{ mr: 1 }} />
                    No location information available
                  </Typography>
                )
              )}
              </Box>

            </Paper>
          ))}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Pagination
              count={Math.ceil(filteredResults.length / resultsPerPage)}
              page={page}
              onChange={(e, value) => setPage(value)}
              color="primary"
            />
          </Box>
            
            </>

          ): (
            <NoResultsFound
              hasActiveFilters={hasActiveFilters()}
              onResetFilters={handleResetFilters}
              onBackToSearch={() => navigate('/find')}
              searchQuery={query}
            />
          )

          }
         
        </Grid>

        {/* Right Column - Insights */}
        <Grid item xs={12} md={3}>
          {randomInsights.map((insight, index) => (
            <Paper
              key={index}
              variant="outlined"
              sx={{
                p: 3,
                mb: 3,
                border: 'none',
                backgroundColor: 'background.paper',
                boxShadow: 0,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                {insight.icon}
                <Typography variant="h6">{insight.title}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {insight.content}
              </Typography>
            </Paper>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}