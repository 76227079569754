
import {unauthApi} from './unauth'


export const getBusinessesByQuickQryApi = unauthApi.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessesByQuickQry: builder.query({
      
      query: ({ query, token}) => `/v2/sp/quickqry?authType=none&token=${token}&query=${query}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useLazyGetBusinessesByQuickQryQuery, usePrefetch } = getBusinessesByQuickQryApi